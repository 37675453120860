<template>
  <div
      class="vertical-layout h-100"
      :class="[layoutClasses]"
      data-col="1-column"
  >
    <!-- Navbar -->
    <b-navbar
        :type="navbarBackground"
        :toggleable="false"
        :variant="navbarBackgroundColor"
        class="header-navbar navbar align-items-center"
        :class="[navbarTypeClass]"
    >
      <slot
          name="navbar"
          :toggleVerticalMenuActive="toggleVerticalMenuActive"
          :navbarBackground="navbarBackground"
          :navbarBackgroundColor="navbarBackgroundColor"
          :navbarTypeClass="[...navbarTypeClass, 'header-navbar navbar navbar-shadow align-items-center']"
      >
        <app-navbar-vertical-layout :toggle-vertical-menu-active="toggleVerticalMenuActive" />
      </slot>
    </b-navbar>
    <!--/ Navbar -->

    <!-- CONTENT TYPE: Left -->
    <transition
        :name="routerTransition"
        mode="out-in"
    >
      <component
          :is="layoutContentRenderer"
          :key="layoutContentRenderer === 'layout-content-renderer-left' ? $route.meta.navActiveLink || $route.name : null"
      >
        <template
            v-for="(index, name) in $scopedSlots"
            v-slot:[name]="data"
        >
          <slot
              :name="name"
              v-bind="data"
          />
        </template>
      </component>
    </transition>
    <!--/ Content -->

    <!-- Footer -->
    <div class="footer-navigation bg-white">
      <b-link to="/">
        <feather-icon
            size="28"
            icon="HomeIcon"
        />
      </b-link>

      <b-link to="/ankieta">
        <feather-icon
            size="28"
            icon="EditIcon"
        />
      </b-link>
      <b-link to="/psycholodzy-psychoterapeuci">
        <feather-icon
            size="28"
            icon="GridIcon"
        />
      </b-link>
      <b-link to="/ustawienia">
        <feather-icon
            size="28"
            icon="SettingsIcon"
        />
      </b-link>

    </div>
    <!-- /Footer -->
  </div>
</template>

<script>
import { onUnmounted } from '@vue/composition-api'
import AppNavbarVerticalLayout from '@core/layouts/components/app-navbar/AppNavbarVerticalLayout.vue'
import AppFooter from '@core/layouts/components/AppFooter.vue'
import useAppConfig from '@core/app-config/useAppConfig'
import LayoutContentRendererDefault from '@core/layouts/components/layout-content-renderer/LayoutContentRendererDefault.vue'
import LayoutContentRendererLeft from '@core/layouts/components/layout-content-renderer/LayoutContentRendererLeft.vue'
import LayoutContentRendererLeftDetached from '@core/layouts/components/layout-content-renderer/LayoutContentRendererLeftDetached.vue'
import VerticalNavMenu from './components/vertical-nav-menu/VerticalNavMenu.vue'
import useVerticalLayout from './useVerticalLayout'
import mixinVerticalLayout from './mixinVerticalLayout'
import { BCol, BRow, BNavbar, BLink} from "bootstrap-vue";

export default {
  components: {
    // AppBreadcrumb,
    AppNavbarVerticalLayout,
    AppFooter,
    VerticalNavMenu,
    BNavbar,
    LayoutContentRendererLeftDetached,
    LayoutContentRendererLeft,
    LayoutContentRendererDefault,
    BCol,
    BRow,
    BLink
  },
  mixins: [mixinVerticalLayout],
  computed: {
    layoutContentRenderer() {
      const rendererType = this.$route.meta.contentRenderer
      if (rendererType === 'sidebar-left') return 'layout-content-renderer-left'
      if (rendererType === 'sidebar-left-detached') return 'layout-content-renderer-left-detached'
      return 'layout-content-renderer-default'
    },
  },
  setup() {
    const {
      routerTransition, navbarBackground, navbarBackgroundColor, navbarType, footerType, isNavMenuHidden,
    } = useAppConfig()

    const {
      isVerticalMenuActive,
      toggleVerticalMenuActive,
      isVerticalMenuCollapsed,
      layoutClasses,
      overlayClasses,
      resizeHandler,
      navbarTypeClass,
      footerTypeClass,
    } = useVerticalLayout(navbarType, footerType)

    // Resize handler
    resizeHandler()
    window.addEventListener('resize', resizeHandler)
    onUnmounted(() => {
      window.removeEventListener('resize', resizeHandler)
    })

    return {
      isVerticalMenuActive,
      toggleVerticalMenuActive,
      isVerticalMenuCollapsed,
      overlayClasses,
      layoutClasses,
      navbarTypeClass,
      footerTypeClass,

      // App Config
      routerTransition,
      navbarBackground,
      navbarBackgroundColor,
      isNavMenuHidden,
    }
  },
}
</script>

<style lang="scss">
@import "~@core/scss/base/themes/bordered-layout.scss";
.app-content.content{
  padding-top: 72.38px !important;
  padding-bottom: 80px !important;
}
.header-navbar.navbar{
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background: #fff;
  box-shadow: 0 1px 18px rgba(0,0,0,.1),0 0 transparent,0 0 transparent;
}
.footer-navigation{
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 20px 0;
  box-shadow: 0 -1px 18px rgba(0,0,0,.1),0 0 transparent,0 0 transparent;
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  a{
    padding-left: 14px;
    padding-right: 14px;
  }
}
</style>